import React, { ReactNode } from 'react'
import { StyleSheet, ViewStyle, View } from 'react-native'

interface Props {
	children: ReactNode
	style?: ViewStyle
	/**
	 * If `true`, there will be horizontal padding of 10 pts.
	 *
	 * Default `false`
	 */
	padding?: boolean
	maxWidth?: number
	/**
	 * Styles for the outer view. Use the `style` prop to style the children's immediate parent.
	 *
	 * The outer view is used to wrap and center the "container". On web, this is achieved with `margin: auto`, but that requies a `display: block`, which we don't have on native. Instead, the outer View has `flexDireciton: row; justifyContent: center`.
	 */
	outerStyle?: ViewStyle
}
/**
 *
 * Component that mimicks the bootstrap container.
 *
 */
export default function Container(props: Props) {
	const style = {
		marginHorizontal: props.padding ? 10 : undefined,
		maxWidth: props.maxWidth || 1190,
		flex: 1,
	}
	return (
		<View style={[styles.container, props.outerStyle]}>
			<View style={[props.style, style]}>{props.children}</View>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		justifyContent: 'center',
	},
})
