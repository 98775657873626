export default [
  { name: "spring_open", x: 34, y: 0, width: 32, height: 39 },
  { name: "spring_closed", x: 0, y: 0, width: 33, height: 19 },
  { name: "grid", x: 0, y: 20, width: 20, height: 20 },
  { name: "block_broken", x: 0, y: 41, width: 76, height: 23 },
  { name: "block", x: 0, y: 130, width: 76, height: 22 },
  { name: "player", x: 0, y: 65, width: 66, height: 64 }
].map(sprite => {
  switch (sprite.name) {
    case "player":
      return { ...sprite, width: 37 };
    case "spring_closed":
      return { ...sprite, width: 30, height: 35, y: 92, x: 43 };
    case "spring_open":
      return { ...sprite, width: 40, x: 36 };
    default:
      return sprite;
  }
});
