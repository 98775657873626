import { AsyncStorage } from "react-native";

export async function saveTotalScoreAsync(score: number) {
  const totalScore = await getTotalScoreAsync();
  await AsyncStorage.setItem("totalscore", `${score + totalScore}`);

  return {
    newTotal: totalScore + score,
    increasedBy: score,
    originalTotal: totalScore
  };
}

export async function getTotalScoreAsync() {
  const score = await AsyncStorage.getItem("totalscore");
  if (score) {
    return parseInt(score);
  }
  return 0;
}
