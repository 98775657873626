import React from 'react'

export default ({ url, webBroswer = true, style = {}, ...props }) => (
	<a
		href={url}
		{...props}
		style={{ textDecorationLine: 'none', ...style }}
		target={webBroswer ? undefined : 'blank'}
	/>
)
