import { useState, useEffect } from 'react'
import { saveHighScoreAsync } from '../utils/high-score'
import { saveTotalScoreAsync } from '../utils/total-score'
import analytics from '../analytics/index'

export default function useScore(props: { score: number }) {
	const [score, setScore] = useState({
		isBest: false,
		high: 0,
		total: 0,
	})

	useEffect(() => {
		;(async () => {
			const [{ score: high, isBest }, { newTotal }] = await Promise.all([
				saveHighScoreAsync(props.score),
				saveTotalScoreAsync(props.score),
			])
			analytics.trackWithProperites({
				event: 'end game',
				properties: {
					highScore: high,
					totalScore: newTotal,
					score: props.score,
				},
			})
			setScore({
				high,
				isBest,
				total: newTotal,
			})
		})()
	}, [props.score])

	return { score }
}
