import { Accelerometer } from 'expo-sensors'
import { GLView } from 'expo-gl'

import React from 'react'
import {
	Dimensions,
	Platform,
	StyleSheet,
	Text,
	View,
	TouchableOpacity,
} from 'react-native'

import DoodleJump from './Game/DoodleJump'
import KeyboardControlsView from './KeyboardControlsView'
import DisableBodyScrollingView from './DisableBodyScrollingView'
import TapControlsView from './TapControlsView'
import JustLost from './JustLost'
const Sensor = Accelerometer

class Jump extends React.Component {
	state = {
		score: 0,
		controls: true,
		size: Dimensions.get('window'),
		justLost: false,
	}

	componentDidMount() {
		this.subscribe()
		console.log('mounts!')
		this.mounted = true
		Dimensions.addEventListener('change', this.onResize)
	}

	onResize = ({ window }) => {
		if (this.game) {
			this.game.resize(window)
		}
		this.setState({ size: window })
	}

	componentWillUnmount() {
		Dimensions.removeEventListener('change', this.onResize)
		this.unsubscribe()
		if (this.game) {
			this.game.deallocate()
		}
		this.mounted = false
	}

	subscribe = async () => {
		const isAvailable = await Sensor.isAvailableAsync()
		console.log('its available', isAvailable)
		if (!isAvailable && Platform.OS !== 'web') {
			this.setState({ controls: false })
			return
		}
		const isAndroid = Platform.OS === 'android'
		// Accelerometer.setUpdateInterval(16);
		const isWeb = Platform.OS === 'web'
		Sensor.addListener(({ x }) => {
			// let inputX = isWeb ? x * 0.3 : x;
			if (this.game) {
				if (isAndroid) {
					this.game.updateControls(x * -1)
				} else {
					this.game.updateControls(x)
				}
			}
		})
	}

	unsubscribe = () => {
		Sensor.removeAllListeners()
	}

	onLose = reset => {
		this.reset = reset
		this.setState({ justLost: true })
	}
	onReset = () => {
		this.setState({ justLost: false }, this.reset)
	}

	onContextCreate = context => {
		this.game = new DoodleJump(
			context,
			score => this.mounted && this.setState({ score }),
			this.onLose
		)
	}

	render() {
		const { size, controls, justLost } = this.state

		if (!controls) {
			return (
				<View
					style={{
						flex: 1,
						backgroundColor: 'red',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Text style={{ fontSize: 16, color: 'white' }}>
						Device Sensors are not available on this device!
					</Text>
				</View>
			)
		}

		return (
			<View
				style={[
					styles.container,
					{ width: size.width, height: size.height },
				]}
			>
				<DisableBodyScrollingView>
					<KeyboardControlsView
						onKeyDown={({ code }) => {
							console.log('here is clicked', code)
							if (this.game) {
								if (code === 'ArrowRight' || code === 'KeyD') {
									this.game.onRight()
								} else if (
									code === 'ArrowLeft' ||
									code === 'KeyA'
								) {
									this.game.onLeft()
								}
							}
						}}
						onKeyUp={() => this.game && this.game.onKeyUp()}
					>
						<GLView
							style={styles.container}
							onContextCreate={this.onContextCreate}
							extraData={this.state.justLost}
						/>
						<Text style={styles.score}>{this.state.score}</Text>
					</KeyboardControlsView>
				</DisableBodyScrollingView>
				<TapControlsView
					onPressIn={({ side }) => {
						if (side === 'left') {
							this.game.onLeft()
						} else if (side === 'right') {
							this.game.onRight()
						}
					}}
					onPressOut={() => this.game && this.game.onKeyUp()}
				/>
				{justLost && (
					<JustLost onReset={this.onReset} score={this.state.score} />
				)}
			</View>
		)
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		maxWidth: '100%',
		maxHeight: '100%',
		overflow: 'hidden',
	},
	score: {
		position: 'absolute',
		top: 30,
		left: 12,
		fontSize: 36,
		opacity: 0.7,
		fontWeight: 'bold',
		color: 'black',
	},
})

// export default () => null;
export default Jump
