import React, { useMemo, useEffect, useRef, memo } from 'react'
import {
	View,
	TouchableOpacity,
	Text,
	Animated,
	Easing,
	StatusBar,
	Image,
	ScrollView,
} from 'react-native'
import { StyleSheet } from 'react-native'
import useScore from './hooks/use-score'
import {
	Button,
	Divider,
	useTheme,
	Card,
	Headline,
	Colors,
	IconButton,
} from 'react-native-paper'
import Container from './components/Container'
import { useSafeArea } from 'react-native-safe-area-context'
import numeral from 'numeral'
import analytics from './analytics/index'
import { Platform } from 'react-native'
import Link from './components/Link'
interface Props {
	score: number
	onReset: () => void
}

export default memo(function JustLost(props: Props) {
	const { score } = useScore({ score: props.score })
	const { style } = useAnimation({ score: !!score })
	const { colors } = useTheme()
	useEffect(() => {
		StatusBar.setBarStyle('light-content', true)
		return () => {
			StatusBar.setBarStyle('dark-content', true)
		}
	}, [])

	const renderStats = () =>
		score && (
			<View>
				<View style={styles.banner}>
					<Text style={[styles.text, styles.header, styles.math]}>
						We did the math...
					</Text>
				</View>
				<View style={styles.row}>
					<View style={styles.column}>
						<Text style={[styles.text, styles.header]}>
							{numeral(score.high).format('0,[000]')}
						</Text>
						<Text style={styles.text}>High score</Text>
					</View>
					<View style={styles.column}>
						<Text
							style={[
								styles.text,
								styles.header,
								styles.mainScore,
								// { color: colors.accent },
							]}
						>
							{numeral(props.score).format('0,[000]')}
						</Text>
						<Text style={styles.text}>Your Score</Text>
					</View>
					<View style={styles.column}>
						<Text style={[styles.text, styles.header]}>
							{numeral(score.total).format('0,[000]')}
						</Text>
						<Text style={styles.text}>Cumulative</Text>
					</View>
				</View>
			</View>
		)

	const actualTweetText = `I just scored ${props.score} on DoodleYang.com by @fernandotherojo. Try to beat it!`

	return (
		<View style={styles.container}>
			<ScrollView>
				<Animated.View
					style={[
						style,
						styles.animated,
						{ backgroundColor: colors.accent },
					]}
				>
					<Container maxWidth={700} padding>
						{renderStats()}
						<Button
							mode="contained"
							onPress={() => {
								analytics.trackWithProperites({
									event: 'restart game',
									properties: {},
								})
								props.onReset()
							}}
						>
							Play Again 🇺🇸
						</Button>
					</Container>
					<View
						style={{
							paddingVertical: 20,
							// width: 400,
							flexDirection: 'row',
							justifyContent: 'center',
						}}
					>
						<View
							style={{
								maxWidth: 500,
							}}
						>
							<Card
								style={{
									backgroundColor: Colors.white,
									padding: 10,
									margin: 10,
								}}
							>
								<View
									style={{
										flexDirection: 'row',
										alignItems: 'center',
									}}
								>
									<IconButton
										icon="twitter"
										color="#1DA1F3"
										size={30}
									/>
									<Text
										style={{
											fontSize: 14,
											fontWeight: 'bold',
											marginLeft: 10,
										}}
									>
										Share DoodleYang on Twitter!
									</Text>
								</View>
								<Divider />
								<Headline
									style={{ marginBottom: 20, marginTop: 10 }}
								>
									{`I just scored ${props.score} on DoodleYang.com. Try to beat it! 🇺🇸 `}
									<Text style={{ color: '#1DA1F3' }}>
										#YangGang2020
									</Text>
								</Headline>
								<View
									style={{
										flexDirection: 'row',
										justifyContent: 'space-between',
										maxWidth: 300,
									}}
								>
									<Link
										url={`https://twitter.com/intent/tweet?text=${actualTweetText} 🇺🇸 &hashtags=YangGang2020`}
										style={{ flex: 1, marginRight: 10 }}
									>
										<Button
											mode="contained"
											contentStyle={{
												backgroundColor: '#1DA1F3',
											}}
											icon="twitter"
										>
											<Text style={{ color: 'white' }}>
												Tweet
											</Text>
										</Button>
									</Link>
									<Link
										url="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdoodleyang.com"
										webBrowser={false}
									>
										<Button
											mode="outlined"
											icon="facebook"
											contentStyle={{
												backgroundColor: 'white',
											}}
											labelStyle={{ color: '#3C5A99' }}
										>
											Share
										</Button>
									</Link>
								</View>
							</Card>
						</View>
					</View>
					<Container padding>
						<View
							style={{
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Link
								webBrowser={false}
								url="https://twitter.com/fernandotherojo"
							>
								<Text
									style={{
										padding: 10,
										textAlign: 'center',
										color: Colors.white,
										fontWeight: 'bold',
									}}
								>
									By{' '}
									<Text
										style={{
											textDecorationLine: 'underline',
										}}
									>
										@fernandotherojo
									</Text>
								</Text>
							</Link>
							<Text
								style={{ color: 'white', marginVertical: 10 }}
							>
								Mobile app coming this week!
							</Text>
						</View>
					</Container>
				</Animated.View>
			</ScrollView>
		</View>
	)
})

function useAnimation(props: { score: boolean }) {
	const { score } = props
	const animatedValue = useRef(new Animated.Value(0)).current
	const { top } = useSafeArea()

	useEffect(() => {
		if (score)
			Animated.timing(animatedValue, {
				toValue: 1,
				useNativeDriver: true,
				duration: 250,
				easing: Easing.linear,
			}).start()
	}, [score])

	return {
		style: {
			opacity: animatedValue,
			transform: [
				{
					translateY: animatedValue.interpolate({
						inputRange: [0, 1],
						outputRange: [-5, 0],
					}),
				},
			],
			paddingTop: top,
		},
	}
}

const styles = StyleSheet.create({
	container: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
	},
	row: {
		flexDirection: 'row',
		alignItems: 'center',
		marginVertical: 20,
	},
	column: {
		flex: 1,
	},
	text: {
		textAlign: 'center',
		color: 'white',
	},
	header: {
		fontWeight: 'bold',
		marginBottom: 5,
		fontSize: 18,
	},
	mainScore: {
		fontSize: 26,
	},
	animated: {
		// backgroundColor: 'white',
		paddingVertical: 10,
	},
	banner: {
		marginTop: 10,
	},
	math: {
		marginTop: Platform.OS === 'web' ? 15 : 10,
		fontSize: 22,
	},
})
