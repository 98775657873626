import React, { ReactNode } from 'react'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { ThemeProvider, DefaultTheme } from 'react-native-paper'

const theme = {
	...DefaultTheme,
	colors: {
		...DefaultTheme.colors,
		primary: '#002C6F',
		accent: '#DA3248',
	},
}

export default function Providers(props: { children: ReactNode }) {
	return (
		<SafeAreaProvider>
			<ThemeProvider theme={theme}>{props.children}</ThemeProvider>
		</SafeAreaProvider>
	)
}
