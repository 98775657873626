import React, { ReactNode } from "react";
import { View, TouchableWithoutFeedback } from "react-native";
import { StyleSheet } from "react-native";

interface Props {
  onPressIn: (info: { side: "left" | "right" }) => void;
  onPressOut: () => void;
}

export default function TapControlsView(props: Props) {
  const left = () => props.onPressIn({ side: "left" });
  const right = () => props.onPressIn({ side: "right" });
  return (
    <View style={styles.container}>
      <div
        onMouseDown={left}
        onMouseUp={props.onPressOut}
        style={item}
        onTouchStart={left}
        onTouchEnd={props.onPressOut}
      />
      <div
        onMouseDown={right}
        onTouchStart={right}
        onMouseUp={props.onPressOut}
        style={item}
        onTouchEnd={props.onPressOut}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    flexDirection: "row"
  },
  item: {
    flex: 1
  }
});

const item = {
  flex: 1
};
