import { TrackWithProperties } from './types'
import Constants from 'expo-constants'

declare global {
	interface Window {
		analytics: any
	}
}
function trackWithProperites(config: TrackWithProperties) {
	window.analytics.track(config.event, {
		...(config.properties || {}),
		sessionId: Constants.sessionId,
	})
}

const analytics = {
	trackWithProperites,
}

export default analytics
