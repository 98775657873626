import React, { useEffect } from 'react'
import Jump from './Jump'
import { StatusBar } from 'react-native'
import Providers from './Providers'
import analytics from './analytics/index'

export default function App() {
	useEffect(() => {
		analytics.trackWithProperites({ event: 'start game', properties: {} })
	}, [])
	return (
		<Providers>
			<StatusBar barStyle="dark-content" />
			<Jump />
		</Providers>
	)
}
