import { AsyncStorage } from "react-native";

export async function saveHighScoreAsync(score: number) {
  const highScore = await getHighScoreAsync();
  if (score > highScore) {
    await AsyncStorage.setItem("hiscore", `${score}`);
  }
  return {
    score: Math.max(score, highScore),
    isBest: score > highScore
  };
}

export async function getHighScoreAsync() {
  const score = await AsyncStorage.getItem("hiscore");
  if (score) {
    return parseInt(score);
  }
  return 0;
}
